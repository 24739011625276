@import '~antd/dist/antd.css';
@import "~react-phone-input-2/lib/style.css";


body {   
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-tel-input .form-control {
  height: 35px;
  width: 200px;
}

.ant-table-tbody > tr .ant-table {
  margin: 0 !important;
}

.ant-table-tbody > .not-active-row {
  background-color: lightgrey;
}

.ant-list-split .ant-list-item {
  border-bottom: 1px solid lightgrey;
}

.site-layout {
  background: #fff;
}
[data-theme="dark"] .site-layout {
  background: #141414;
}
.site-header {
  line-height: 32px;
  height: 32px;
  _position: sticky;
  width: 100%;
  padding: 0 5px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}
.row-dragging .drag-visible {
  visibility: visible;
}
.row-v-alig-top {
  vertical-align: top !important;
}
.bg-light-green {
  background-color: #f6ffed;
}

.login_form {
  min-Width: 40%;
  background-Color: #dae2ef;
  position: absolute;
  top: 25%;
  left: 30%;
  remove_padding-Top: 50px;
  border: #c1c1c1 solid 1px;
}
#login_form input:-webkit-autofill {
    -webkit-background-clip: text;
}
.ant-form-item {
  margin-bottom: 8px !important;
}
