.ant-table-tbody > tr.ant-table-row-level-1:hover > td {
  background: unset;
}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: unset;
}

.ant-table-cell-row-hover {
  background: unset;
}
